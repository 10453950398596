import React from 'react';
import Logo from '../images/avatarHospitalDefault.png';
import { useEffect, useState } from 'react';
import { callingAPI } from '../config';
import { uploadToS3 } from '../uploadToS3';
import AWS from 'aws-sdk';
import Loader from '../Common/loader';
import { faLessThanEqual } from '@fortawesome/free-solid-svg-icons';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
var s3;

var getS3folder =
	process.env.REACT_APP_S3_URI +
	process.env.REACT_APP_S3_ENVIRONMEWNT_TYPE +
	process.env.REACT_APP_S3_HOSPITAL_LOGO_PATH;
function Printouts() {
	const [load, setLoad] = useState(false);
	const [file, setFile] = useState('');
	const [printHospitalData, setPrintHospitalData] = useState({});
	const [flag, setFlag] = useState(false);
	const [DisplayLogoFlag, setDisplayLogoFlag] = useState(false);
	const [DisplayLogoFormatFlag, setDisplayLogoFormatFlag] = useState(false);
	const [imageName, setImageName] = useState();
	const [PictureName, setPictureName] = useState('');
	const [pictureForUpload, setPictureForUpload] = useState('');
	const [includeLogo, setIncludeLogo] = useState(true);
	const [flagsave, setflagsave] = useState(false);
	const [flagsave1, setflagsave1] = useState(false);
	const [styleleft, setstyleleft] = useState();
	const [showEditDiv, setShowEditDiv] = useState(false);
	const [logoType, setIncludeLogoType] = useState(0);
	const [marginTop, setMarginTop] = useState(0);
	const [marginLeft, setMarginLeft] = useState('');

	const [marginRight, setMarginRight] = useState(0);
	const [marginBottom, setMarginBottom] = useState(0);
	const [getPicturename, setgetPicturename] = useState('');
	const [printOutPageSizePageSizeName, setprintOutPageSizePageSizeName] = useState('');
	const [selctedPrintoutPageSizeID, setSelctedPrintoutPageSizeID] = useState(0);
	const [printOutPageSizeDropDown, setPrintOutPageSizeDropDown] = useState([]);
	const [printOutPrinterTypes, setPrintOutPrinterTypes] = useState([]);
	const [printOutLogoTypes, setPrintOutLogoTypes] = useState([]);
	const [selctedPrinterID, setSelctedPrinterID] = useState(0);
	const [imageflag, setimageflag] = useState(false);
	const [printOutHospitalHeaderTitle, setprintOutHospitalHeaderTitle] = useState('');
	const [printOutHospitalHeaderLefText, setprintOutHospitalHeaderLefText] = useState('');
	const [printOutHospitalHeaderRightText, setprintOutHospitalHeaderRightText] = useState('');
	const [printOutHospitalFooterFullWidthContent, setprintOutHospitalFooterFullWidthContent] = useState('');
	const [printOutHospitalFooterLeftSignature, setprintOutHospitalFooterLeftSignature] = useState('');
	const [printOutHospitalFooterRightSignature, setprintOutHospitalFooterRightSignature] = useState('');
	const [saveFlag, setSaveFlag] = useState(false)
	AWS.config.update({
		// accessKeyId: process.env.REACT_APP_S3_ACCESS_KEY,
		secretAccessKey: process.env.REACT_APP_S3_SECRET_KEY,
		region: 'us-east-1',
	});
	var bucketParams = {
		Bucket: process.env.REACT_APP_S3_BUCKET_NAME,
	};
	s3 = new AWS.S3({ apiVersion: '2006-03-01', params: bucketParams });

	const textchangetitle = (e) => {
		setprintOutHospitalHeaderTitle(e.currentTarget.value);
	};
	const textchangeleft = (e) => {
		setprintOutHospitalHeaderLefText(e.currentTarget.value);
	};
	const textchangeright = (e) => {
		setprintOutHospitalHeaderRightText(e.currentTarget.value);
	};
	const textchangefullwidth = (e) => {
		setprintOutHospitalFooterFullWidthContent(e.currentTarget.value);
	};
	const Signaturetextchangeleft = (e) => {
		setprintOutHospitalFooterLeftSignature(e.currentTarget.value);
	};
	const Signaturetextchangeright = (e) => {
		setprintOutHospitalFooterRightSignature(e.currentTarget.value);
	};
	const cancelUpdate = () => {
		setShowEditDiv(false);
	};
	function IncludeLogo() {
		var x = document.getElementById('myDIV');
		document.getElementById('narrow').style.display = 'block';
		document.getElementById('wide').style.display = 'block';
		document.getElementById('square').style.display = 'block';
		x.style.display = 'none';
		document.getElementById('myDIVUpload').style.display = 'none';
		setFlag(false);
	}

	function NotIncludeLogo(e) {
		var x = document.getElementById('myDIV');
		document.getElementById('narrow').style.display = 'block';
		document.getElementById('wide').style.display = 'block';
		document.getElementById('square').style.display = 'block';
		x.style.display = 'none';
		document.getElementById('myDIVUpload').style.display = 'none';
		setFlag(true);
	}
	const onChangePictureUpload = (e) => {

		var x = document.getElementById('myDIV');
		document.getElementById('narrow').style.display = 'block';
		document.getElementById('wide').style.display = 'block';
		document.getElementById('square').style.display = 'block';
		x.style.display = 'none';
		setFile(e.target.files[0]);
		if (e.target.files.length !== 0) {
			setimageflag(false)
			setPictureForUpload(URL.createObjectURL(e.target.files[0]));
			setImageName(e.target.files[0].name.replace(/ /g, '-'));

			setPictureName('')
		}


	};
	if (imageflag == false) {

	} else {

	}

	useEffect(() => {
		callingAPI('printhospital', {
			hospitalId: localStorage.getItem("hospitalId"),
		}).then((res) => {
			console.log("🚀 ~ useEffect ~ res:", res)
			if (res.data.success == 1) {
				setLoad(true)
				setPictureName(res.data.result.hospitalPrintOutList[0].hospitalImagesLogoImage);
				setgetPicturename(res.data.result.hospitalPrintOutList[0].hospitalImagesLogoImage);
				setPrintHospitalData(res.data.result.hospitalPrintOutList[0]);
				setprintOutHospitalHeaderTitle(res.data.result.hospitalPrintOutList[0].printOutHospitalHeaderTitle);
				setprintOutHospitalHeaderLefText(res.data.result.hospitalPrintOutList[0].printOutHospitalHeaderLefText);
				setprintOutHospitalHeaderRightText(res.data.result.hospitalPrintOutList[0].printOutHospitalHeaderRightText);
				setprintOutHospitalFooterFullWidthContent(
					res.data.result.hospitalPrintOutList[0].printOutHospitalFooterFullWidthContent
				);
				setprintOutHospitalFooterLeftSignature(
					res.data.result.hospitalPrintOutList[0].printOutHospitalFooterLeftSignature
				);
				setprintOutHospitalFooterRightSignature(
					res.data.result.hospitalPrintOutList[0].printOutHospitalFooterRightSignature
				);
				setIncludeLogo(res.data.result.hospitalPrintOutList[0].printOutHospitalHeaderIncludeLogo);
				setIncludeLogoType(res.data.result.hospitalPrintOutList[0].printOutLogoTypeId);
				setMarginTop(res.data.result.hospitalPrintOutList[0].printOutHospitalPageMarginTop);
				setMarginLeft(res.data.result.hospitalPrintOutList[0].printOutHospitalPageMarginLeft);
				setMarginRight(res.data.result.hospitalPrintOutList[0].printOutHospitalPageMarginRight);
				setMarginBottom(res.data.result.hospitalPrintOutList[0].printOutHospitalPageMarginBottom);
				setSelctedPrintoutPageSizeID(res.data.result.hospitalPrintOutList[0].printOutPageSizeId);
				setPrintOutPageSizeDropDown(res.data.result.printOutPageSize);
				setPrintOutPrinterTypes(res.data.result.printOutPrinterType);
				setSelctedPrinterID(res.data.result.hospitalPrintOutList[0].printOutPrinterTypeId);
				setPrintOutLogoTypes(res.data.result.printOutLogoType);
				setprintOutPageSizePageSizeName(res.data.result.hospitalPrintOutList[0].printOutPageSizePageSizeName);
			}
			else { setLoad(true) }
		});


	}, []);

	const getIncludeLogo = (e) => {
		if (e.currentTarget.value === 'Yes') {
			setIncludeLogo(true);
		} else {
			setIncludeLogo(false);
		}
	};
	const savePrintHospitals = () => {
		if (flagsave == true) {
			setflagsave(false)
			toast.error("Height and Width should be less than 50px")
		}
		else if (flagsave1 == true) {
			setflagsave1(false)
			toast.error('Please attach jpeg or png files')
		}

	}
	const myArray = printOutPageSizeDropDown.filter(function (obj) {
		return obj.printOutPageSizeName !== 'Customized';
	});

	const uuidv4 = () => {
		return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
			const r = (Math.random() * 16) | 0,
				v = c === 'x' ? r : (r & 0x3) | 0x8;
			return v.toString(16);
		});
	};

	const savePrintHospital = () => {
		var randomValue = uuidv4();
		setSaveFlag(true)
		const postData = {
			userId: window.sessionStorage.getItem('userId'),
			printOutHospitalFooterFullWidthContent: document.getElementById('footerFullWidthContent').value,
			printOutHospitalFooterLeftSignature: document.getElementById('footerLeftSignature').value,
			printOutHospitalFooterRightSignature: document.getElementById('footerRightSignature').value,
			printOutHospitalHeaderIncludeLogo: includeLogo,

			printOutHospitalHeaderLefText: document.getElementById('leftText').value,
			printOutLogoTypeId: logoType,
			hospitalImagesLogoImage: (imageName == null || includeLogo == false) ? "" : randomValue + imageName.replace(/\s+/g, '-')
			.replace(/[()]/g, '')
			.replace(/-+/g, '-'),
			printOutHospitalHeaderRightText: document.getElementById('rightText').value,
			printOutHospitalHeaderTitle: document.getElementById('titleText').value,
			hospitalId: localStorage.getItem("hospitalId"),
			printOutHospitalPageMarginBottom: document.getElementById('pageMarginBottom').value,
			printOutHospitalPageMarginLeft: document.getElementById('pageMarginLeft').value,
			printOutHospitalPageMarginRight: document.getElementById('pageMarginRight').value,
			printOutHospitalPageMarginTop: document.getElementById('pageMarginTop').value,
			printOutOrientationTypeId: document.getElementById('orientationId').value,
			printOutPrinterTypeId: document.getElementById('printerId').value,
			printOutPageSizeId: document.getElementById('pageSizeName').value,
			printOutHospitalIsDefault: 1,
			isActive: 1,
			printOutHospitalId: printHospitalData.printOutHospitalId,
		};


		callingAPI('printhospital/printHospitalAddUpdate', postData).then(async (res) => {
			if (res.data.success == 1) {
				if (file != '') {
					const sanitizedFileName = file.name?.replace(/\s+/g, '-')
			.replace(/[()]/g, '')
			.replace(/-+/g, '-');
		const sanitizedFile = new File([file], sanitizedFileName, { type: file.type });

					const s3Url = await uploadToS3(sanitizedFile, process.env.REACT_APP_S3_HOSPITAL_LOGO_PATH, randomValue)
					console.log("s3Url printout................", s3Url)
				}

				toast.success('success');
				setTimeout(function () {
					window.location.href = '/settings/printouts';
				}, 1400);
			} else {
				toast.error('failed');
			}
		});
	};
	const getLogoType = (e) => {
		setIncludeLogoType(e.currentTarget.value);
		if (e.currentTarget.value === '1') {
			setShowEditDiv(false);
			document.getElementById('narrow').style.display = 'none';
			document.getElementById('wide').style.display = 'none';
			document.getElementById('square').style.display = 'block';
			document.getElementById('myDIV').style.display = 'none';
		} else if (e.currentTarget.value === '2') {
			setShowEditDiv(false);
			document.getElementById('wide').style.display = 'none';
			document.getElementById('narrow').style.display = 'block';
			document.getElementById('square').style.display = 'none';
			document.getElementById('myDIV').style.display = 'none';
		} else {
			setShowEditDiv(false);
			document.getElementById('wide').style.display = 'block';
			document.getElementById('narrow').style.display = 'none';
			document.getElementById('square').style.display = 'none';
			document.getElementById('myDIV').style.display = 'none';
		}
	};
	function UploadPictureValidation() {
		const DisplayLogo = () => {
			var x = document.getElementById('myDIV');
			x.style.display = 'none';
			document.getElementById('narrow').style.display = 'none';
			document.getElementById('wide').style.display = 'none';
			document.getElementById('square').style.display = 'none';
		};
		//Get reference of FileUpload.
		var fileUpload = document.getElementById('fileUpload');

		//Check whether the file is valid Image.
		var regex = new RegExp('([a-zA-Z0-9s_\\.-:])+(|.jpg|.png|.gif)$');
		if (regex.test(fileUpload.value.toLowerCase())) {
			//Check whether HTML5 is supported.
			if (typeof fileUpload.files != 'undefined') {
				//Initiate the FileReader object.
				var reader = new FileReader();
				//Read the contents of Image File.
				reader.readAsDataURL(fileUpload.files[0]);
				reader.onload = function (e) {
					//Initiate the JavaScript Image object.
					var image = new Image();

					//Set the Base64 string return from FileReader as source.
					image.src = e.target.result;

					//Validate the File Height and Width.
					image.onload = function () {
						var height = this.height;
						var width = this.width;

						const NotDisplayLogo = () => {
							var x = document.getElementById('myDIV');
							x.style.display = 'block';
							document.getElementById('narrow').style.display = 'none';
							document.getElementById('wide').style.display = 'none';
							document.getElementById('square').style.display = 'none';
						};
						const DisplayLogo = () => {
							var x = document.getElementById('myDIV');
							x.style.display = 'none';
							document.getElementById('narrow').style.display = 'none';
							document.getElementById('wide').style.display = 'none';
							document.getElementById('square').style.display = 'none';
						};
						if (height < 50 || width < 50) {
							setflagsave(true)

							setDisplayLogoFlag(height < 50 || width < 50)

							setTimeout(function () {
								toast.error('Width should be:50px and Height should be:50px');
							}, 80);
							return DisplayLogo();
						}
						setflagsave(false)
						setDisplayLogoFlag()

						return NotDisplayLogo;
					};
				};
			} else {

				toast.error('This browser does not support HTML5.');
				return DisplayLogo();
			}

		} else {
			setflagsave1(true)
			setTimeout(function () {
				toast.error('Please attach jpeg or png files');
			}, 80);

			setDisplayLogoFormatFlag(true)
			return DisplayLogo();

		}
		setflagsave1(false)
		setDisplayLogoFormatFlag()
	}


	if (selctedPrintoutPageSizeID == '3') {

		var printsizevalueB4 = '96px'
	}
	else if (selctedPrintoutPageSizeID == '5') {
		var printsizevalueLetter = '100px'
	}


	const imageS3Save = async (e) => {

		const s3Url = await uploadToS3(file, process.env.REACT_APP_S3_HOSPITAL_LOGO_PATH)
		console.log("s3Url printout................", s3Url)
		// var S3BucketMainFolder =
		// 	process.env.REACT_APP_S3_ENVIRONMEWNT_TYPE + process.env.REACT_APP_S3_HOSPITAL_LOGO_PATH + '/';
		// var fullFilePath = S3BucketMainFolder + + localStorage.getItem("hospitalId") + ".png";

		// s3.upload(
		// 	{
		// 		Key: fullFilePath,
		// 		Body: file,
		// 		ACL: 'public-read',
		// 	},
		// 	(err, data) => {
		// 		if (err) {
		// 			toast.error('There was an error uploading your photo: ' + err.message);
		// 			return null;
		// 		} else {


		// 		}
		// 	}
		// );

	};
	const getLogoNochange = (e) => {
		if (flag == false) {
			getLogoType(e);
		} else {
			return false;
		}
	};
	// 	const marginleftvalue = (e)=>{
	// if(e.currentTarget.value == 1){

	// 	setMarginLeft('200px')
	// 	document.getElementById('div181').style.display = 'block';

	// }
	// else if(e.target.value == 2){
	// 	setstyleleft('58px')
	// }


	// 	}
	return (
		<div className="practiceManagementForm">
			<ToastContainer position="bottom-right" />
			<div className="col-md-12 col-lg-12">
				<h3 className="practiceHead">Printouts </h3>
			</div>
			<div className="clearfix" />
			<div className="row">
				<div class="col-md-4 col-lg-4">
					<div class="practiceManagementFormBlcok">
						<form name="frm" enctype="multipart/form-data" id="frm">
							<input type="hidden" value="HOSPITAL" name="roleText" />
							<ul>
								<div class="LimitScrollDiv2" style={{ padding: '10px', paddingLeft: '0px' }}>
									<div class="clearfix">
										{load === false ? <Loader /> : ''}
										<h4 class="greenLink" style={{ fontWeight: 'normal', fontSize: '16px' }}>
											Header
										</h4>
										<li>
											<label style={{ fontWeight: 'normal' }}>
												Hospital Logo&nbsp;
												{/* <font color="red">Minimum Size(50x50px)</font> */}
											</label>

											{DisplayLogoFlag || DisplayLogoFormatFlag || PictureName == null ?

												<img src={Logo} /> :
												<img id="imageThumb2" src={

													imageflag == false ?
														pictureForUpload ||
														getS3folder + '/' + PictureName: Logo

												} />
											}
											<input
												type="file"
												onChange={(e) => {
													onChangePictureUpload(e);
													UploadPictureValidation();
												}}
												name="file"
												accept="image/x-png,image/gif,image/jpeg"
												id="fileUpload"
											/>
										</li>
										<li>
											<label style={{ fontWeight: 'normal' }}>Title</label>{' '}
											<input
												id="titleText"
												name="leftText"
												defaultValue={printHospitalData.printOutHospitalHeaderTitle}
												placeholder="Title of Hospital"
												type="text"
												maxlength="250"
												onChange={textchangetitle}
											/>
										</li>
										<li>
											<label style={{ fontWeight: 'normal' }}>Left Text</label>{' '}
											<input
												id="leftText"
												name="leftText"
												defaultValue={printHospitalData.printOutHospitalHeaderLefText}
												placeholder="Left Text"
												type="text"
												maxlength="250"
												onChange={textchangeleft}
											/>
										</li>
										<li>
											<label style={{ fontWeight: 'normal' }}>Right Text</label>{' '}
											<input
												id="rightText"
												name="rightText"
												defaultValue={printHospitalData.printOutHospitalHeaderRightText}
												placeholder="Right Text"
												type="text"
												onChange={textchangeright}
												maxlength="250"
											/>
										</li>
										<li class="no-gutter row">
											<div class="col-md-6 col-lg-6">
												<label style={{ fontWeight: 'normal' }}>Include Logo </label>
												<div class="practiceRadio practiceRadio-primary practiceRadioSet">
													<div class="radio">
														<input
															id="radio-1"
															value="Yes"
															onClick={getIncludeLogo}
															onChange={IncludeLogo}
															name="IncludeLogo"
															type="radio"
															checked={includeLogo}
														/>
														<label for="radio-1" class="radio-label">
															Yes
														</label>
													</div>

													<div class="radio">
														<input
															id="radio-2"
															value="No"
															onClick={getIncludeLogo}
															onChange={NotIncludeLogo}

															name="IncludeLogo"
															type="radio"
															checked={!includeLogo}
														/>
														<label for="radio-2" class="radio-label">
															No
														</label>
													</div>
												</div>
											</div>

											<div class="col-md-6 col-lg-6">
												<label style={{ fontWeight: 'normal' }}>Logo Type </label>
												<div class="practiceRadio practiceRadio-primary practiceRadioSet">
													{printOutLogoTypes.map((item) => (
														<div class="radio">
															<input
																id={'radio' + item.printOutLogoTypeId}
																value={item.printOutLogoTypeId}
																checked={logoType == item.printOutLogoTypeId}
																onChange={getLogoNochange}
																name={'logoType' + item.printOutLogoTypeId}
																type="radio"
															/>
															<label
																for={'radio' + item.printOutLogoTypeId}
																class="radio-label"
															>
																{item.printOutLogoTypeName}
															</label>
														</div>
													))}
												</div>
											</div>
										</li>
									</div>

									<div class="clearfix">
										<h4 class="greenLink" style={{ fontWeight: 'normal', fontSize: '16px' }}>
											Footer
										</h4>
										<li>
											<label style={{ fontWeight: 'normal' }}>Left Signature</label>
											<input
												id="footerLeftSignature"
												defaultValue={printHospitalData.printOutHospitalFooterLeftSignature}
												placeholder="Left Signature"
												type="text"
												name="footerfullWidth"
												maxlength="255"
												onChange={Signaturetextchangeleft}
											/>
										</li>
										<li>
											<label style={{ fontWeight: 'normal' }}>Right Signature</label>
											<input
												id="footerRightSignature"
												defaultValue={printHospitalData.printOutHospitalFooterRightSignature}
												placeholder="Right Signature"
												type="text"
												name="footerfullWidth"
												maxlength="255"
												onChange={Signaturetextchangeright}
											/>
										</li>
										<li>
											<label style={{ fontWeight: 'normal' }}>Full Width Content</label>
											<input
												id="footerFullWidthContent"
												defaultValue={printHospitalData.printOutHospitalFooterFullWidthContent}
												placeholder="Full Width Content"
												type="text"
												name="footerfullWidth"
												maxlength="255"
												onChange={textchangefullwidth}
											/>
										</li>
									</div>

									<div class="clearfix">
										<h4 class="greenLink" style={{ fontWeight: 'normal', fontSize: '16px' }}>
											Page Setup
										</h4>
										<li>
											<label style={{ fontWeight: 'normal' }}>Printer type</label>

											<select
												id="printerId"
												value={selctedPrinterID}
												onChange={(e) => setSelctedPrinterID(e.target.value)}
											>
												{printOutPrinterTypes.map((item) => (
													<option value={item.printOutPrinterTypeId}>
														{item.printOutPrinterTypeName}
													</option>
												))}
											</select>
										</li>

										<li>
											<label style={{ fontWeight: 'normal' }}>Printer Orientation</label>
											<select
												id="orientationId"
												value={printHospitalData.printOutOrientationTypeId}
											>
												<option value="1">Normal</option>
											</select>
										</li>
										<li>
											<label>
												<strong>Margins</strong>
											</label>
											<div className="row">
												<div className="col-md-6">
													<label style={{ fontWeight: 'normal' }}>Left</label>
													<select
														id="pageMarginLeft"
														value={marginLeft}
														onChange={(e) => { setMarginLeft(e.target.value) }}
													>
														<option value="0">0 px</option>
														<option value="9">9 px</option>
														<option value="24">24 px</option>
														<option value="48">48 px</option>
														<option value="72">72 px</option>
														<option value="96">96 px</option>
														<option value="120">120 px</option>
														<option value="144">144 px</option>
														<option value="168">168 px</option>
														<option value="192">192 px</option>
														<option value="216">216 px</option>
														<option value="240">240 px</option>


													</select>
												</div>
												<div className="col-md-6">
													<label style={{ fontWeight: 'normal' }}>Right</label>
													<select
														id="pageMarginRight"
														value={marginRight}
														onChange={(e) => { setMarginRight(e.target.value); }}
													>
														<option value="0">0 px</option>
														<option value="9">9 px</option>
														<option value="24">24 px</option>
														<option value="48">48 px</option>
														<option value="72">72 px</option>
														<option value="96">96 px</option>
														<option value="120">120 px</option>
														<option value="144">144 px</option>
														<option value="168">168 px</option>
														<option value="192">192 px</option>
														<option value="216">216 px</option>
														<option value="240">240 px</option>
													</select>
												</div>
												<div className="col-md-6">
													<label style={{ fontWeight: 'normal' }}>Top</label>
													<select
														id="pageMarginTop"
														value={marginTop}
														onChange={(e) => setMarginTop(e.target.value)}
													>
														<option value="0">0 px</option>
														<option value="9">9 px</option>
														<option value="24">24 px</option>
														<option value="48">48 px</option>
														<option value="72">72 px</option>
														<option value="96">96 px</option>
														<option value="120">120 px</option>
														<option value="144">144 px</option>
														<option value="168">168 px</option>
														<option value="192">192 px</option>
														<option value="216">216 px</option>
														<option value="240">240 px</option>
													</select>
												</div>
												<div className="col-md-6">
													<label style={{ fontWeight: 'normal' }}>Bottom</label>
													<select
														id="pageMarginBottom"
														value={marginBottom}
														onChange={(e) => setMarginBottom(e.target.value)}
													>
														<option value="0">0 px</option>
														<option value="9">9 px</option>
														<option value="24">24 px</option>
														<option value="48">48 px</option>
														<option value="72">72 px</option>
														<option value="96">96 px</option>
														<option value="120">120 px</option>
														<option value="144">144 px</option>
														<option value="168">168 px</option>
														<option value="192">192 px</option>
														<option value="216">216 px</option>
														<option value="240">240 px</option>
													</select>
												</div>
											</div>
										</li>
										<li>
											<label style={{ fontWeight: 'normal' }}>Size</label>
											<select
												id="pageSizeName"
												value={selctedPrintoutPageSizeID}
												onChange={(e) => {
													setSelctedPrintoutPageSizeID(e.target.value);
												}}
											>
												{myArray.map((item) => (
													<option value={item.printOutPageSizeId}>
														{item.printOutPageSizeName}
													</option>
												))}
											</select>
										</li>
										{/* <li>
											<label>
												<strong>Right Signature</strong>
											</label>
											<input
												id="customeWidth"
												defaultValue={printHospitalData.printOutHospitalFooterRightSignature}
												placeholder="Right Signature"
												type="text"
												name="footerfullWidth"
												maxlength="255"
											/>
										</li> */}
									</div>
								</div>

								<li>
									{flagsave == false && flagsave1 == false ?
										<button
											disabled={saveFlag}
											type="button"
											class="practiceGreenButton"
											// onclick="return validateFunction()"
											onClick={() => {
												savePrintHospital();
												cancelUpdate();
											}}
											value="Save"
										>
											Save
										</button> :
										<button
											disabled={saveFlag}
											type="button"
											class="practiceGreenButton"
											// onclick="return validateFunction()"
											onClick={() => {
												savePrintHospitals();
												cancelUpdate();
											}}
											value="Save"
										>
											Save
										</button>}
									{/* <input type="button" className="practiceGreyButton" value="submit" onClick={savePrintHospital} /> */}
									{/* <!-- <button type="button" class="practiceGreyButton" value="Cancel">Cancel</button> --> */}
								</li>
							</ul>
						</form>
					</div>
				</div>



				<div class="col-md-8 col-lg-8 iframeBlockPractice" >

					<div id="iFrameReport">
						<div class="iframePrintOutDiv" align="center" style={{ width: '100%', marginLeft: marginLeft + 'px', marginRight: marginRight + 'px', marginTop: marginTop + 'px', marginBottom: marginBottom + 'px' }}
						>
							<table style={{ width: '100%', maxWidth: '100%', marginLeft: printsizevalueB4 || printsizevalueLetter }} border="0">
								<tbody>
									<tr height="80px">
										<td colspan="3" valign="middle" align="center">
											<div class="practicePrintlogoSquare">
												<p
													style={{
														fontWeight: '400',
														fontSize: '14px',
														clear: 'both',
														width: '100%',
														margin: '0',
													}}
												>
													<span
														style={{
															marginTop: '-5px',
															display: 'inline-block',
															width: '100%',
														}}
													>
														<div id="myDIV" style={{ display: showEditDiv ? '' : 'none' }}>
															{includeLogo == true && (
																<img
																	src={
																		imageflag == false ?
																			pictureForUpload ||
																			getS3folder + '/' + localStorage.getItem("hospitalId") + ".png" : ""
																	}
																	style={{ display: !showEditDiv ? '' : 'none' }}
																	align="middle"
																/>
															)}
														</div>
														<div id="myDIVUpload">
															<img
																src={
																	imageflag == false ?
																		pictureForUpload ||
																		getS3folder + '/' + localStorage.getItem("hospitalId") + ".png" : ""
																}
																style={{ display: showEditDiv ? '' : 'none' }}
																align="middle"
															/>
														</div>

														<div
															id="narrow"
															style={{ display: !showEditDiv ? '' : 'none' }}
														>
															{logoType == '2' &&
																includeLogo == true && (
																	<div>
																		{PictureName != null ?
																			<img
																				src={
																					imageflag == false ?
																						pictureForUpload ||
																						getS3folder + '/' + localStorage.getItem("hospitalId") + ".png" : ""
																				}
																				style={{
																					width: '140px',
																					height: 'auto',
																					float: 'left',
																				}}
																				align="left"
																			/> :
																			<img
																				src={
																					Logo
																				}
																				style={{
																					width: '140px',
																					height: 'auto',
																					float: 'left',
																				}}
																				align="left"
																			/>}
																		<div class="practicePrintName practiceNameSquare " style={{
																			float: "left",
																			width: "75%",
																			textAlign: "center"
																		}}>
																			<p
																				style={{
																					fontWeight: 'bold',
																					fontSize: '14px',
																					clear: 'both',
																					width: '100%',
																					marginBottom: '3px',
																				}}
																			>
																				{printOutHospitalHeaderTitle}{' '}
																			</p>
																			<p
																				style={{
																					fontWeight: '400',
																					fontSize: '14px',
																					clear: 'both',
																					width: '100%',
																					margin: '0px',
																				}}
																			>
																				{printHospitalData.hospitalAddress}
																			</p>
																		</div>
																	</div>
																)}
															{logoType == '2' &&
																includeLogo == false && (
																	<div>
																		{/* <img
																			src={
																				imageflag == false ?
																					pictureForUpload ||
																					getS3folder + '/' + localStorage.getItem("hospitalId") + ".png" : ""
																			}
																			style={{
																				width: '140px',
																				height: 'auto',
																				float: 'left',
																			}}
																			align="left"
																		/> */}
																		<div class="practicePrintName practiceNameSquare " style={{
																			float: "left",
																			width: "75%",
																			textAlign: "center"
																		}}>
																			<p
																				style={{
																					fontWeight: 'bold',
																					fontSize: '14px',
																					clear: 'both',
																					width: '100%',
																					marginBottom: '3px',
																				}}
																			>
																				{printOutHospitalHeaderTitle}{' '}
																			</p>
																			<p
																				style={{
																					fontWeight: '400',
																					fontSize: '14px',
																					clear: 'both',
																					width: '100%',
																					margin: '0px',
																				}}
																			>
																				{printHospitalData.hospitalAddress}
																			</p>
																		</div>
																	</div>
																)}
														</div>
														<div id="wide" style={{ display: !showEditDiv ? '' : 'none', width: "100%" }}>
															{logoType == '3' &&
																includeLogo == true && (
																	<div>
																		{PictureName != null ?
																			<img
																				src={
																					imageflag == false ?
																						pictureForUpload ||
																						getS3folder + '/' + localStorage.getItem("hospitalId") + ".png" : ""
																				}
																				style={{
																					width: '150px',
																					float: 'left',
																					height: 'auto',
																				}}
																				align="left"
																			/> :
																			<img
																				src={
																					Logo
																				}
																				style={{
																					width: '150px',
																					float: 'left',
																					height: 'auto',
																				}}
																				align="left"
																			/>}
																		<div class="practicePrintName practiceNameSquare " style={{
																			float: "left",
																			width: "75%",
																			textAlign: "center"
																		}}>
																			<p
																				style={{
																					fontWeight: 'bold',
																					fontSize: '14px',
																					clear: 'both',
																					width: '100%',
																					marginBottom: '3px',
																				}}
																			>
																				{printOutHospitalHeaderTitle}{' '}
																			</p>
																			<p
																				style={{
																					fontWeight: '400',
																					fontSize: '14px',
																					clear: 'both',
																					width: '100%',
																					margin: '0px',
																				}}
																			>
																				{printHospitalData.hospitalAddress}
																			</p>
																		</div>
																	</div>
																)}
															{logoType == '3' &&
																includeLogo == false && (
																	<div>
																		{/* <img
																			src={
																				imageflag == false ?
																					pictureForUpload ||
																					getS3folder + '/' + localStorage.getItem("hospitalId") + ".png" : ""
																			}
																			style={{
																				width: '150px',
																				float: 'left',
																				height: 'auto',
																			}}
																			align="left"
																		/> */}
																		<div class="practicePrintName practiceNameSquare " style={{
																			float: "left",
																			width: "75%",
																			textAlign: "center"
																		}}>
																			<p
																				style={{
																					fontWeight: 'bold',
																					fontSize: '14px',
																					clear: 'both',
																					width: '100%',
																					marginBottom: '3px',
																				}}
																			>
																				{printOutHospitalHeaderTitle}{' '}
																			</p>
																			<p
																				style={{
																					fontWeight: '400',
																					fontSize: '14px',
																					clear: 'both',
																					width: '100%',
																					margin: '0px',
																				}}
																			>
																				{printHospitalData.hospitalAddress}
																			</p>
																		</div>
																	</div>
																)}
														</div>
														<div
															id="square"
															style={{ display: !showEditDiv ? '' : 'none' }}
														>
															{logoType == '1' &&
																includeLogo == true && (
																	<div>
																		{PictureName != null ?
																			<img
																				src={
																					imageflag == false ?
																						pictureForUpload ||
																						getS3folder + '/' + PictureName : ""
																				}
																				style={{
																					width: '160px',
																					height: 'auto',
																					float: 'middle',
																				}}
																				align="middle"
																			/> : <img
																				src={
																					Logo
																				}
																				style={{
																					width: '160px',
																					height: 'auto',
																					float: 'middle',
																				}}
																				align="middle"
																			/>}
																		<div class="practicePrintName practiceNameSquare ">
																			<p
																				style={{
																					fontWeight: 'bold',
																					fontSize: '14px',
																					clear: 'both',
																					width: '100%',
																					marginBottom: '3px',
																				}}
																			>
																				{printOutHospitalHeaderTitle}{' '}
																			</p>
																			<p
																				style={{
																					fontWeight: '400',
																					fontSize: '14px',
																					clear: 'both',
																					width: '100%',
																					margin: '0px',
																				}}
																			>
																				{printHospitalData.hospitalAddress}
																			</p>
																		</div>
																	</div>
																)}
															{logoType == '1' &&
																includeLogo == false && (
																	<div>
																		{/* <img
																			src={
																				imageflag == false ?
																					pictureForUpload ||
																					getS3folder + '/' + localStorage.getItem("hospitalId") + ".png" : ""
																			}
																			style={{
																				width: '160px',
																				height: 'auto',
																				float: 'middle',
																			}}
																			align="middle"
																		/> */}
																		<div class="practicePrintName practiceNameSquare ">
																			<p
																				style={{
																					fontWeight: 'bold',
																					fontSize: '14px',
																					clear: 'both',
																					width: '100%',
																					marginBottom: '3px',
																				}}
																			>
																				{printOutHospitalHeaderTitle}{' '}
																			</p>
																			<p
																				style={{
																					fontWeight: '400',
																					fontSize: '14px',
																					clear: 'both',
																					width: '100%',
																					margin: '0px',
																				}}
																			>
																				{printHospitalData.hospitalAddress}
																			</p>
																		</div>
																	</div>
																)}
														</div>
													</span>
												</p>
											</div>
											{/* <div class="practicePrintName practiceNameSquare ">
												<p
													style={{
														fontWeight: 'bold',
														fontSize: '16px',
														clear: 'both',
														width: '100%',
														marginBottom: '3px',
													}}
												>
													{printOutHospitalHeaderTitle}{' '}
												</p>
												<p
													style={{
														fontWeight: '400',
														fontSize: '14px',
														clear: 'both',
														width: '100%',
														margin: '0px',
													}}
												>
													{printHospitalData.hospitalAddress}
												</p>
											</div> */}
										</td>
									</tr>
									<tr style={{ borderBottom: '.5px solid black', paddingTop: '5px' }}>
										<th
											style={{
												borderBottom: '.5px solid black',
												textAlign: 'left',
												fontSize: '600',
											}}
										/>
										<th
											style={{
												borderBottom: '.5px solid black',
												textAlign: 'left',
												fontSize: '600',
											}}
										/>
										<th
											style={{
												borderBottom: '.5px solid black',
												textAlign: 'right',
												fontSize: '600',
												paddingRight: '10px',
											}}
										/>
									</tr>

									{/* <tr style={{ display: 'table-row' }}>
										<td colspan="3">
											<hr color="#142536" />
										</td>
									</tr>
									<tr style={{ display: 'table-row' }}>
										<td colspan="1" style={{ textAlign: 'left' }}>
											<span style={{ width: '95%%', float: 'left', textAlign: 'left!important' }}>
												Mishras clinic
											</span>
										</td>

										<td colspan="1" style={{ textAlign: 'right' }}>
											<span
												style={{ width: '100%', float: 'left', textAlign: 'right!important' }}
											>
												Phone: 9980999999
											</span>
										</td>
									</tr>

									<tr style={{ display: 'table-row' }}>
										<td colspan="3">
											<hr color="#142536" />
										</td>
									</tr> */}
									<tr style={{ display: 'table-row' }}>
										<td
											colspan="1"
											style={{ textAlign: 'left', paddingTop: '20px', fontSize: '12px', width: "50%" }}
										>
											<span style={{ width: '95%', float: 'left', textAlign: 'left!important' }}>
												{/* Dr Nagarjun Mishra,<br /> MBBS, DM, <br /> Emergency Physician<br /> */}
												{printOutHospitalHeaderLefText}
											</span>
										</td>
										<td style={{ textAlign: 'center', paddingTop: '20px', fontSize: '12px' }}>
											<span
												style={{
													width: '100%',
													float: 'right',
													align: 'right',
													textAlign: 'right',
													width: '100px',
												}}
											/>
										</td>

										<td
											colspan="1"
											style={{ textAlign: 'right', paddingTop: '20px', fontSize: '12px', width: "50%" }}
										>
											<span
												style={{
													width: '100%',
													float: 'right',
													align: 'right',
													textAlign: 'right',
												}}
											>
												{/* 123/12, Behind SBT,<br /> Jubilee Hills, <br />Hyderabad */}
												{printOutHospitalHeaderRightText}
											</span>
										</td>
									</tr>

									<tr style={{ borderBottom: '.5px solid black' }}>
										<th
											style={{
												borderBottom: '.5px solid black',
												textAlign: 'left',
												fontSize: '600',
												paddingBottom: '15px',
											}}
										/>
										<th
											style={{
												borderBottom: '.5px solid black',
												textAlign: 'left',
												fontSize: '600',
												paddingBottom: '15px',
											}}
										/>
										<th
											style={{
												borderBottom: '.5px solid black',
												textAlign: 'right',
												fontSize: '600',
												paddingRight: '10px',
												paddingBottom: '15px',
											}}
										/>
									</tr>
									<br />
									<tr style={{ display: 'table-row' }}>
										<td width="50%" align="left" style={{ fontSize: '12px', textAlign: 'left' }}>
											<span style={{ textAlign: 'left!important' }}> Name : Patient Name</span>
											<br />
											<span style={{ textAlign: 'left!important' }}> MR No : 00001</span> <br />
											<span style={{ textAlign: 'left!important' }}> Age : 2 Year </span>
											<br />
											<span style={{ textAlign: 'left!important' }}> Sex : Male </span>
											<br />
										</td>

										<td
											width="50%"
											align="center"
											style={{ fontSize: '12px', textAlign: 'left' }}
										/>

										<td width="50%" align="right" style={{ fontSize: '12px' }}>
											<span style={{ textAlign: 'right!important', float: 'right' }}>
												Date : 01 Jan 1990
											</span>
											<br />
											<span style={{ textAlign: 'right!important', float: 'right' }}>
												Phone No : 900000000
											</span>
											<br />
											<span style={{ textAlign: 'right!important', float: 'right' }}>
												Email Id : email@gmail.com
											</span>
											<br />
										</td>
									</tr>
									<br />
									<tr style={{ borderBottom: '.5px solid black' }}>
										<th
											style={{
												borderBottom: '.5px solid black',
												textAlign: 'left',
												fontSize: '600',
											}}
										/>
										<th
											style={{
												borderBottom: '.5px solid black',
												textAlign: 'left',
												fontSize: '600',
											}}
										/>
										<th
											style={{
												borderBottom: '.5px solid black',
												textAlign: 'right',
												fontSize: '600',
												paddingRight: '10px',
											}}
										/>
									</tr>
									<tr>
										<td colspan="3" />
									</tr>

									<tr>
										<td colspan="3">
											<table width="100%">
												<thead
													style={{
														background: 'none',
														height: '30px',
														fontSize: '0.9em',
														color: '#393939',
													}}
												>
													<tr>
														<th
															style={{
																textAlign: 'left',
																fontSize: '600',
															}}
														/>
														<th
															style={{
																textAlign: 'left',
																fontSize: '600',
															}}
														/>
														<th
															style={{
																textAlign: 'right',
																fontSize: '600',
																paddingRight: '10px',
															}}
														/>
													</tr>
													<tr>
														<td
															style={{
																fontSize: '14px',
																color: '#393939',
																paddingTop: '5px',
																borderBottom: '1px solid #ddd',
															}}
														>
															<p class="sml_matter" />
														</td>
														<td
															style={{
																fontSize: '14px',
																color: '#393939',
																paddingTop: '5px',
																borderBottom: '1px solid #ddd',
															}}
														/>
														<td
															style={{
																fontSize: '14px',
																color: '#393939',
																paddingTop: '5px',
																borderBottom: '1px solid #ddd',
															}}
														>
															<span />
														</td>
													</tr>
													<tr>
														<td
															style={{
																fontSize: '14px',
																color: '#393939',
																paddingTop: '5px',
																borderBottom: '1px solid #ddd',
															}}
														>
															<p class="sml_matter">
																<span class="sml_head"> </span>{' '}
															</p>
														</td>
														<td
															style={{
																fontSize: '14px',
																color: '#393939',
																paddingTop: '5px',
																borderBottom: '1px solid #ddd',
															}}
														/>
														<td
															style={{
																fontSize: '14px',
																color: '#393939',
																paddingTop: '5px',
																borderBottom: '1px solid #ddd',
															}}
														>
															<span />
														</td>
													</tr>
													<tr>
														<td
															style={{
																fontSize: '14px',
																color: '#393939',
																paddingTop: '5px',
																borderBottom: '1px solid #ddd',
															}}
														>
															<p class="sml_matter">
																<span class="sml_head"> </span>{' '}
															</p>
														</td>
														<td
															style={{
																fontSize: '14px',
																color: '#393939',
																paddingTop: '5px',
																borderBottom: '1px solid #ddd',
															}}
														/>
														<td
															style={{
																fontSize: '14px',
																color: '#393939',
																paddingTop: '5px',
																borderBottom: '1px solid #ddd',
															}}
														>
															<span />
														</td>
													</tr>
													<tr>
														<td
															style={{
																fontSize: '14px',
																color: '#393939',
																paddingTop: '5px',
																borderBottom: '1px solid #ddd',
															}}
														>
															<p class="sml_matter">
																<span class="sml_head"> </span>{' '}
															</p>
														</td>
														<td
															style={{
																fontSize: '14px',
																color: '#393939',
																paddingTop: '5px',
																borderBottom: '1px solid #ddd',
															}}
														/>
														<td
															style={{
																fontSize: '14px',
																color: '#393939',
																paddingTop: '5px',
																borderBottom: '1px solid #ddd',
															}}
														>
															<span />
														</td>
													</tr>
													<tr>
														<td
															style={{
																fontSize: '14px',
																color: '#393939',
																paddingTop: '5px',
																borderBottom: '1px solid #ddd',
															}}
														>
															<p class="sml_matter">
																<span class="sml_head"> </span>{' '}
															</p>
														</td>
														<td
															style={{
																fontSize: '14px',
																color: '#393939',
																paddingTop: '5px',
																borderBottom: '1px solid #ddd',
															}}
														/>
														<td
															style={{
																fontSize: '14px',
																color: '#393939',
																paddingTop: '5px',
																borderBottom: '1px solid #ddd',
															}}
														>
															<span />
														</td>
													</tr>{' '}
													<tr>
														<td
															style={{
																fontSize: '14px',
																color: '#393939',
																paddingTop: '5px',
																borderBottom: '1px solid #ddd',
															}}
														>
															<p class="sml_matter">
																<span class="sml_head"> </span>{' '}
															</p>
														</td>
														<td
															style={{
																fontSize: '14px',
																color: '#393939',
																paddingTop: '5px',
																borderBottom: '1px solid #ddd',
															}}
														/>
														<td
															style={{
																fontSize: '14px',
																color: '#393939',
																paddingTop: '5px',
																borderBottom: '1px solid #ddd',
															}}
														>
															<span />
														</td>
													</tr>{' '}
													<tr>
														<td
															style={{
																fontSize: '14px',
																color: '#393939',
																paddingTop: '5px',
																borderBottom: '1px solid #ddd',
															}}
														>
															<p class="sml_matter">
																<span class="sml_head"> </span>{' '}
															</p>
														</td>
														<td
															style={{
																fontSize: '14px',
																color: '#393939',
																paddingTop: '5px',
																borderBottom: '1px solid #ddd',
															}}
														/>
														<td
															style={{
																fontSize: '14px',
																color: '#393939',
																paddingTop: '5px',
																borderBottom: '1px solid #ddd',
															}}
														>
															<span />
														</td>
													</tr>{' '}
													<tr>
														<td
															style={{
																fontSize: '14px',
																color: '#393939',
																paddingTop: '5px',
																borderBottom: '1px solid #ddd',
															}}
														>
															<p class="sml_matter">
																<span class="sml_head"> </span>{' '}
															</p>
														</td>
														<td
															style={{
																fontSize: '14px',
																color: '#393939',
																paddingTop: '5px',
																borderBottom: '1px solid #ddd',
															}}
														/>
														<td
															style={{
																fontSize: '14px',
																color: '#393939',
																paddingTop: '5px',
																borderBottom: '1px solid #ddd',
															}}
														>
															<span />
														</td>
													</tr>
												</thead>
											</table>
										</td>
									</tr>
									<br />
									<tr style={{ borderBottom: '.5px solid black' }}>
										<th
											style={{
												borderBottom: '.5px solid black',
												textAlign: 'left',
												fontSize: '600',
											}}
										/>
										<th
											style={{
												borderBottom: '.5px solid black',
												textAlign: 'left',
												fontSize: '600',
											}}
										/>
										<th
											style={{
												borderBottom: '.5px solid black',
												textAlign: 'right',
												fontSize: '600',
												paddingRight: '10px',
											}}
										/>
									</tr>
									<tr>
										<td colspan="3">
											<div align="justify" class="practiceFootBtm">
												<div style={{ float: 'left', fontSize: '12px', paddingTop: '20px' }}>
													{printOutHospitalFooterLeftSignature}
												</div>
												<div
													style={{ textAlign: 'right', fontSize: '12px', paddingTop: '20px' }}
												>
													{printOutHospitalFooterRightSignature}
												</div>
											</div>
										</td>
									</tr>
									<tr>
										<td colspan="3">
											<div align="justify" class="practiceFootBtm">
												<div style={{ float: 'left' }} />
												<div style={{ textAlign: 'center', fontSize: '12px' }}>
													{printOutHospitalFooterFullWidthContent}
												</div>
											</div>
										</td>
									</tr>


									<tr style={{ borderBottom: '.5px solid black' }}>
										<th
											style={{
												borderBottom: '.5px solid black',
												textAlign: 'left',
												fontSize: '600',
											}}
										/>
										<th
											style={{
												borderBottom: '.5px solid black',
												textAlign: 'left',
												fontSize: '600',
											}}
										/>
										<th
											style={{
												borderBottom: '.5px solid black',
												textAlign: 'right',
												fontSize: '600',
												paddingRight: '10px',
											}}
										/>
									</tr>
								</tbody>
							</table>
						</div>
					</div>
				</div>

				{/* } */}

			</div>
		</div>
	);
}

export default Printouts;
